.hero {
  background-color: #0b2241;
  margin-top: 10px;
  min-height: 398px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.hero p {
  color: white;
  padding-top: 10px;
}
.hero h1 {
  color: white;
  font-size: 24px;
  padding-bottom: 10px;
}
.hero div {
  flex-grow: 1;
  flex-basis: 0;
}
.hero .hero-message {
  padding: 20px;
}

.hero .hero-image {
  background-image: url('../../assets/img/2037_D22_0466_FR.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 398px;
}

.hero a {
  color: white;
  
  &:hover, &:visited {
    color: white;
  }
  &:focus {
    color: black;
  }
}
