@import '~@cpms/common/styles/common.scss';

main h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  color: #191919;
}

main a {
  text-decoration: underline;
  color: var(--cobalt);
}

.transition {
  transition: var(--transition);
}
.info-card {
  padding: 20px 0;
  background-color: #fff;
  margin: 10px 0;
  border-top: 1px solid #6d7278;
}

.referral {
  text-decoration: underline;
  color: var(--cobalt);
  margin: 0 3px;
}

.if-you-are-not-sure {
  margin: 30px 0 0 2px;
  color: #191919;
}

.bottom-card {
  padding: 20px 30px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
}