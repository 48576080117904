.breadcrumbs-item {
  &:not(:last-child) {
    position: relative;
    margin-right: 45px;

    &:after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12.5 0 9 4.5-9 4.5V5H0V4h12.5V0Z' fill='%239E9E9E' fill-rule='nonzero'/%3E%3C/svg%3E");      background-position: center center;
      background-repeat: no-repeat;
      width: 22px;
      height: 9px;
      position: absolute;
      top: 5px;
      right: -35px;
    }
  }
}
